/** @jsx jsx */
import { Link } from "gatsby"
import { Box, Flex, Heading, Text, jsx } from "theme-ui"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import theme from "../gatsby-plugin-theme-ui"
import AuthorsListCompact from "./AuthorsListCompact"
import { BsFillPlayFill } from "react-icons/bs"

const PostCard = ({ post }) => {
  const fields = {
    title: post?.title,
    excerpt: post?.excerpt || ``,
    featuredImage: {
      data: getImage(post.featuredImage?.node?.localFile),
      alt: post.featuredImage?.node?.altText || post.title || ``,
    },
    authors: post.postCustomFields?.authors?.map(author => {
      return {
        image: {
          data: author?.featuredImage?.node?.localFile?.childImageSharp
            ?.gatsbyImageData,
          alt:
            author?.featuredImage?.altText || author?.authorFields?.name || ``,
        },
        name: author?.authorFields?.name,
        id: author?.id,
      }
    }),
    date: {
      formatted: post?.date,
      machineReadable: post?.dateMachineReadable,
    },
    url: `/blog/${post.slug}/`,
    primaryTag: post.postCustomFields.topic?.title,
    accentColor:
      post.postCustomFields.topic?.topicCustomFields?.accentColor ||
      theme.colors.primary.base,
    template: post.postCustomFields?.template,
  }

  return (
    <Box as="article">
      <Link to={fields.url}>
        <Flex sx={{ flexDirection: "column" }}>
          {fields.featuredImage?.data && (
            <Box sx={{ position: "relative" }}>
              <GatsbyImage
                image={fields.featuredImage.data}
                alt={fields.featuredImage.alt}
                style={{
                  overflow: "hidden",
                  height: "228px",
                  borderRadius: theme.radii[5] + "px",
                  boxShadow: theme.shadows.cards,
                }}
                imgStyle={{
                  borderRadius: theme.radii[5] + "px",
                  objectFit: "cover",
                }}
              />
              {fields.template === "presentation" && (
                <div
                  sx={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    sx={{
                      size: ["60px"],
                      backgroundColor: "background.base",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "100%",
                    }}
                  >
                    <BsFillPlayFill
                      sx={{
                        color: "tertiary.base",
                        fontSize: "27px",
                      }}
                    />
                  </div>
                </div>
              )}
            </Box>
          )}
          <Box mt={4}>
            {fields.primaryTag && (
              <Heading
                as="div"
                variant="uppercase"
                mb={3}
                sx={{ color: fields.accentColor }}
              >
                {fields.primaryTag}
              </Heading>
            )}
            <Heading
              as="h3"
              variant="md"
              sx={{
                maxHeight: "93px",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
            >
              {fields.title}
            </Heading>
            <Text
              as="div"
              variant="sm"
              dangerouslySetInnerHTML={{ __html: fields.excerpt }}
              sx={{
                maxHeight: "110px",
                overflow: "hidden",
                mt: 3,
                "& p": {
                  margin: 0,
                },
                display: "-webkit-box",
                WebkitLineClamp: 4,
                WebkitBoxOrient: "vertical",
              }}
            />
            <div sx={{ mt: 4 }}>
              <AuthorsListCompact
                collapsed
                authors={fields.authors}
                date={fields.date}
              />
            </div>
          </Box>
        </Flex>
      </Link>
    </Box>
  )
}

export default PostCard
