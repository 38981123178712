/** @jsx jsx */
import { jsx, Box, Flex, Text, Heading, Container } from "theme-ui"
import AuthorBio from "../AuthorBio"

const Header = ({ fields: { title, author } }) => (
  <Box as="header">
    <Container py={10} variant="narrow">
      <Heading as="h1" variant="xl" sx={{ textAlign: "center" }}>
        {title}
      </Heading>
      <Box mt={10}>
        <Heading as="h2" variant="uppercase" mb={5}>
          Introduction by
        </Heading>
        <AuthorBio author={author} />
      </Box>
    </Container>
  </Box>
)

export default Header
