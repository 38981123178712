/** @jsx jsx */
import { jsx, Box, Grid, Heading, Container } from "theme-ui"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import parse from "html-react-parser"
import Header from "../components/topic/Header"
// import SecondaryNav from "../components/topic/SecondaryNav"
import articleTypography from "../styles/article-typography"
import { replaceCode } from "../components/PostCode"
import PostCard from "../components/PostCard"
import SEO from "../components/seo"

const TopicTemplate = ({ data: { topic, posts } }) => {
  const fields = {
    title: topic.title,
    author: {
      image: {
        data: topic.topicCustomFields.author?.featuredImage?.node?.localFile
          ?.childImageSharp?.gatsbyImageData,
        alt:
          topic.topicCustomFields.author?.featuredImage?.node?.altText ||
          `Picture of ${topic.topicCustomFields?.author?.authorFields?.name}`,
      },
      name: topic.topicCustomFields.author?.authorFields?.name,
      bio: topic.topicCustomFields.author?.authorFields?.bio,
      twitter: topic.topicCustomFields.author?.authorFields?.twitter,
      linkedin: topic.topicCustomFields.author?.authorFields?.linkedin,
      website: topic.topicCustomFields.author?.authorFields?.website,
    },
  }
  return (
    <Layout>
      <SEO yoastSeo={topic.seo} />
      {/* <SecondaryNav /> */}
      <article>
        <Header fields={fields} />
        {!!topic.content && (
          <Box as="section" mt={0}>
            <Grid
              sx={{
                gridGap: 0,
                gridTemplateColumns:
                  "[full-start] minmax(25px,auto) [wide-start] minmax(auto,207.5px) [main-start] calc(min(730px, 100% - 50px)) [main-end] minmax(auto,207.5px) [wide-end] minmax(25px,auto) [full-end]",
                "& > *": {
                  gridColumn: "main-start/main-end",
                },
                ...articleTypography,
              }}
            >
              {parse(topic.content, {
                replace: replaceCode,
              })}
            </Grid>
          </Box>
        )}
      </article>
      <Box as="section" mt={10} mb={10}>
        <Container>
          <Heading as="h2" variant="lg" mb={7}>
            Resources
          </Heading>
          <Grid
            sx={{
              gridTemplateColumns: ["1fr", "1fr 1fr", null, "repeat(3, 1fr)"],
              gridGap: "64px 40px",
            }}
          >
            {posts.edges.map(({ node }, index) => {
              return <PostCard key={node.id} post={node} />
            })}
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

export default TopicTemplate

export const pageQuery = graphql`
  query ($id: String!, $topicTaxonomySlug: String) {
    topic: wpTopic(id: { eq: $id }) {
      ...WpTopicFields
    }
    posts: allWpPost(
      filter: {
        topictaxonomy: {
          nodes: { elemMatch: { slug: { eq: $topicTaxonomySlug } } }
        }
      }
    ) {
      edges {
        node {
          ...WpPostCardFields
        }
      }
    }
  }
`
